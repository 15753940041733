<template>
  <div style="background-color: white; height: 100vh">
    <v-app-bar app color="primary" elevation="2" dense flat dark>
      <div class="text-h3 text-center">{{ info.periodo }}</div>
      <v-spacer></v-spacer>
      <v-btn icon @click="close"><v-icon>mdi-close</v-icon></v-btn>

      <!--template v-slot:actions>
              <router-link  to="/admin/solicitudes/list-desembolsado"  class="mr-2 white-text" >
                Volver
              </router-link>
          
         </template-->
    </v-app-bar>
    <div v-if="info" class="px-3" :key="renderKey">
      <br /><br /><br />
      <b>Periodo del informe:</b> <span>{{ info.periodo }}</span> <br />
      <b>Acreedor:</b> <span>{{ info.acreedor }}</span> <br />
      * Tasas expresadas en términos nominales mensuales / anuales
      <br /><br />
      <div class="text-center">
        <h2 class="mb-2">Resumen cartera de créditos activos</h2>
        <h3 class="mb-2">Detalle de Cartera</h3>

        <v-simple-table
          class="report-tables"
          style="height: 200px; overflow: auto"
        >
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left" style="width: 50px">No</th>
                <th class="text-left" style="width: 90px">Crédito</th>
                <th class="text-left" style="width: 180px">Cliente</th>
                <th class="text-left" style="width: 80px">Fecha Desembolso</th>
                <th class="text-left" style="width: 120px">Producto</th>
                <th class="text-right" style="width: 160px">Vr. Crédito</th>
                <th class="text-left" style="width: 80px">Plazo (meses)</th>
                <th class="text-left" style="width: 60px">Tasa (NM)</th>
                <th class="text-right" style="width: 160px">Saldo al corte</th>
                <th class="text-left" style="width: 80px">Estado (C|M)(*)</th>
                <th class="text-left" style="width: 50px">Días mora</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, index) in info.creditos" :key="item.id">
                <td style="width: 50px">{{ index + 1 }}</td>
                <td style="width: 90px">{{ item.solicitud_id }}</td>
                <td style="width: 180px">{{ item.cliente }}</td>
                <td style="width: 80px">
                  {{ $filters.date(item.f_desembolso) }}
                </td>
                <td style="width: 120px">{{ item.producto }}</td>
                <td class="text-right" style="width: 160px">
                  {{ $filters.currency(item.valor_credito) }}
                </td>
                <td style="width: 80px">{{ item.plazo }}</td>
                <td style="width: 60px">{{ $filters.ceil100(item.tasa) }}%</td>
                <td class="text-right" style="width: 160px">
                  {{ item.saldo | currency }}
                </td>
                <td style="width: 80px">{{ item.estado }}</td>
                <td style="width: 50px">{{ item.dias_mora }}</td>
              </tr>
            </tbody>

            <tfoot>
              <tr>
                <th class="text-right" colspan="5">Total</th>

                <th class="text-right" style="width: 160px">
                  {{ calcTotalCreditos() | currency }}
                </th>
                <th class="text-left" style="width: 80px"></th>
                <th class="text-left" style="width: 60px"></th>
                <th class="text-right" style="width: 160px">
                  {{ calcTotalSaldo() | currency }}
                </th>
                <th class="text-left" style="width: 80px"></th>
                <th class="text-left" style="width: 50px"></th>
              </tr>
            </tfoot>
          </template>
        </v-simple-table>
      </div>

      <small>*. C: Corriente; M: Mora </small>
      <br /><br />

      <h3 class="mb-2 mt-4" v-if="info.distribucion_cartera_xproducto">
        Distribución % cartera por producto
      </h3>

      <GChart
        type="PieChart"
        :data="info.distribucion_cartera_xproducto"
        :options="chartDistribucionCarteraProducto.chartOptions"
      />

      <h2 class="mb-2 mt-3">Rentabilidades generadas</h2>

      <h3 class="mb-2 mt-2">Cartera total de créditos</h3>

      <v-row>
        <v-col cols="12" md="6">
          <v-simple-table
            v-if="info.periodo"
            style="display: flex; align-items: center; height: 100%"
          >
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Periodo</th>
                  <th class="text-left">Rentabilidad (NM)</th>
                  <th class="text-left">Rendimiento ($)</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{{ info.periodo }}</td>
                  <td>
                    {{
                      $filters.ceil100(info.rentabilidad_generada_periodo.rentabilidad)
                    }}%
                  </td>
                  <td>
                    {{
                      $filters.currency(info.rentabilidad_generada_periodo.rendimiento)
                    }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>Acumulado Año<br />{{ info.periodo_anho }}</th>
                  <th>
                    {{
                      $filters.ceil100(sumarItems(
                        info.rentabilidad_generada_periodo_anho,
                        "rentabilidad"
                      ))
                    }}%
                  </th>
                  <th>
                    {{
                      $filters.currency(sumarItems(
                        info.rentabilidad_generada_periodo_anho,
                        "rendimiento"
                      ))
                    }}
                  </th>
                </tr>

                <tr>
                  <th>Saldo K al cierre</th>
                  <th></th>
                  <th>
                    {{
                      info.rentabilidad_generada_periodo.total_capital
                        | currency
                    }}
                  </th>
                </tr>
              </tfoot>
            </template>
          </v-simple-table>
        </v-col>

        <v-col cols="12" md="6" v-if="info.rentabilidad_generada_periodo_anho">
          <GChart
            type="ColumnChart"
            :data="
              getDataChartRentabilidadGeneradaPorAnho(
                info.rentabilidad_generada_periodo_anho
              )
            "
            :options="chartRentabilidadesProducto.chartOptions"
          />
        </v-col>
      </v-row>


<h3 class="mb-2 mt-2">Rentabilidad por Producto / Acumulado año </h3>
  
  <v-row>
    <v-col cols="12" md="6">
    
    <v-simple-table>
    <template v-slot:default>
      <thead >
       <tr>
          <th class="text-center" colspan="3">Periodo: {{info.periodo}}</th>
        </tr>
        <tr>
          <th class="text-left">Producto</th>
          <th class="text-left">Rentabilidad (NM)</th>
          <th class="text-left">Rendimiento ($)	</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in info.rentabilidad_generada_periodo_xproducto" :key="index">
          <td>{{index}}</td>
          <td>{{$filters.ceil100(item.rentabilidad)}}%</td>
          <td>{{$filters.currency(item.rendimiento)}}</td>
        </tr>
      </tbody>
      
      <tfoot v-if="info.rentabilidad_generada_periodo_xproducto">
        <tr>
         <th colspan="2">Total:</th>
          <th>{{$filters.currency(calcTotalRendimiento())}}</th>
        </tr>
      </tfoot>
    </template>
  </v-simple-table>
    </v-col>
    
    <v-col cols="12" md="6">
        <GChart type="ColumnChart" :data="getDataChartRentabilidadGeneradaPorAnhoPorProducto(info.rentabilidad_generada_periodo_anho_xproducto)" 
        :options="chartRentabilidades.chartOptions" />
    </v-col>
  </v-row>
  
  <div>
  <h2 class="mb-2 mt-6"> Pagos recibidos / Rentabilidad ejecutada </h2>

<div v-if="info.total_pagos_recibidos_periodo && info.total_pagos_recibidos_periodo.length > 0">
<v-simple-table class="report-tables"   >
    <template v-slot:default>
      <thead >
        <tr>
          <th class="text-left" style="width:48">No</th>
          <th class="text-left" style="width:76">Crédito</th>
          <th class="text-left" style="width:9%">Cliente	</th>
          <th class="text-left" style="width:9%">Producto	</th>
          <th class="text-left" style="width:9%">Saldo K</th>
          <th class="text-left" style="width:9%">Valor Pagado	</th>
          
          <th class="text-left" style="width:9%">Interés (C+M) (*)	</th>
          <th class="text-left" style="width:9%">Otros Cobros (**)</th>
          <th class="text-left" style="width:9%">Aporte K	</th>
          <th class="text-left" style="width:9%">Costo neto (***)</th>
          <th class="text-left" style="width:9%">Interés neto</th>
          <th class="text-left" style="width:9%">Rent. Ejec.</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in info.total_pagos_recibidos_periodo" :key="item.id">
          <td style="width:48">{{index+1}}</td>
          <td style="width:76">{{ item.credito }}</td>
          <td style="width:9%">{{ item.cliente }}</td>
          <td style="width:9%">{{ item.producto }}</td>
          <td style="width:9%">{{$filters.currency(item.saldo_capital)}}</td>
          <td style="width:9%">{{$filters.currency(item.valor_pagado)}}</td>
          <td style="width:9%">{{$filters.currency(item.interes)}}</td>
          <td style="width:9%">{{$filters.currency(item.otros)}}</td>
          <td style="width:9%">{{$filters.currency(item.aporte_capital)}}</td>
          <td style="width:9%">{{ $filters.currency(item.costo_neto) }}</td>
          <td style="width:9%">{{ $filters.currency(item.interes_neto) }}</td>
          <td style="width:9%">{{$filters.ceil100(item.rentabilidad_ejecutada)}}%</td>
        </tr>
      </tbody>
      <tfoot>
          <tr>
          <th colspan="4">SUBTOTAL</th>
          <th style="width:9%">{{$filters.currency(calcularTotalCapital(info.total_pagos_recibidos_periodo, 'saldo_capital'))}}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'valor_pagado'))}}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'interes'))}}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'otros'))}}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'aporte_capital'))}}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'costo_neto')) }}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'interes_neto')) }}</th>
          <th style="width:9%"></th>
        </tr>
        
        <tr>
          <th colspan="4">(-) Costo de transferencia</th>
          <th style="width:9%"></th>
          <th style="width:9%"></th>
          <th style="width:9%"></th>
          <th style="width:9%"></th>
          <th style="width:9%"></th>
          <th style="width:9%">{{ $filters.currency(getCostoTransferencia(info.transferencia)) }}</th>
          <th style="width:9%"> - {{ $filters.currency(getCostoTransferencia(info.transferencia)) }}</th>
          <th style="width:9%"></th>
        </tr>
        
        <tr>
          <th colspan="4">TOTAL</th>
          <th style="width:9%">{{$filters.currency(calcularTotalCapital(info.total_pagos_recibidos_periodo, 'saldo_capital'))}}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'valor_pagado'))}}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'interes'))}}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'otros'))}}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'aporte_capital'))}}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'costo_neto') + getCostoTransferencia(info.transferencia)) }}</th>
          <th style="width:9%">{{$filters.currency(sumarItems(info.total_pagos_recibidos_periodo, 'interes_neto') - getCostoTransferencia(info.transferencia)) }}</th>
          <th style="width:9%">{{$filters.ceil100(info.costos_generados_periodo.rentabilidad_neta)}}%</th>
        </tr>
      </tfoot>
       </template>
  </v-simple-table>
  
  <small>
  *. C: Corriente; M: Mora<br>
  **. Seguros + otros cobros. <br>
  ***. Costo asociado a la administración de cartera: Incluye comisión + costo recaudo (incluye IVA)

  </small>
</div>
  </div>
  <div>
  <v-row class="mt-5" v-if="info.total_pagos_recibidos_periodo && info.total_pagos_recibidos_periodo.length > 0">
  <v-col cols="12" md="6" >
        <b>{{pieChartDistribucionPagosProducto.chartOptions.title}}</b>
        
        <GChart type="PieChart" :data="info.distribucion_pagos_xproducto" :options="pieChartDistribucionPagosProducto.chartOptions" />
    </v-col>
  <v-col cols="12" md="6"  >
       
        <b>{{pieChartDistribucionInteresProducto.chartOptions.title}}</b>
        <GChart type="PieChart" :data="info.distribucion_interes_xproducto" :options="pieChartDistribucionInteresProducto.chartOptions" />
    </v-col>
    
  <v-col cols="12" md="12" v-if="info.costos_generados_periodo_anho">

        <GChart type="ColumnChart" :data="info.costos_generados_periodo_anho" :options="barChartCostoRentabilidad.chartOptions" />
    </v-col>
    
  </v-row>
  
  </div>
  
  <h2 class="mt-6">Estado de Cartera</h2>
  
  
   <v-row no-gutters> 
           
           <v-col cols="12" sm="6" class="form-col" style="border: 1px solid #ccc;">
              <v-subheader>Información clientes</v-subheader>
              <v-progress-circular v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-simple-table v-if="info && info.detalle_cartera.clientes">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-left"># Cuotas</th>
                      <th class="text-right">x Cobrar</th>
                    </tr>
                  </thead>
                  <tbody v-if="info.detalle_cartera.clientes">
                    <tr>
                      <td >Clientes Sin Deuda</td>
                      <td>{{ info.detalle_cartera.clientes.total_clientes_sin_deuda }}</td>
                      <td class="text-right">{{ 0 | currency }}</td>
                    </tr>
                    <tr>
                      <td>Cuotas Con Deuda No Vencida</td>
                      <td>{{ info.detalle_cartera.clientes.total_clientes_deuda_no_vencida }}</td>
                      <td class="text-right">{{ info.detalle_cartera.clientes.total_deuda_no_vencida | currency }}</td>
                    </tr>
                    <tr>
                      <td>Cuotas Con Deuda Vencida</td>
                      <td>{{ info.detalle_cartera.clientes.total_clientes_deuda_vencida }}</td>
                      <td class="text-right">{{ info.detalle_cartera.clientes.total_deuda_vencida | currency }}</td>
                    </tr>
                    
                  </tbody>
                  <tfoot>
                  <tr>
                      <th>Total</th>
                      <th>{{ info.detalle_cartera.clientes.total_clientes }}</th>
                      <th class="text-right">{{ info.detalle_cartera.clientes.total_xpagar | currency }}</th>
                    </tr>
                    </tfoot>
                </template>
              </v-simple-table>
           </v-col>
           
           <v-col cols="12" sm="6" class="form-col" style="border: 1px solid #ccc">
            <GChart type="PieChart" :data="getInfoPieClientes()" :options="info.detalle_cartera.optionsPieClientes" />
           </v-col>
       
            <v-col cols="12" sm="6" class="form-col" style="border: 1px solid #ccc"  >
            <v-subheader>Distribución cartera</v-subheader>
              <v-simple-table v-if="info">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left"></th>
                      <th class="text-right">x Cobrar</th>
                      <th class="text-right">%</th>
                    </tr>
                  </thead>
                  <tbody>
                  <template v-for="(t, index) in info.detalle_cartera.estadoCartera">
                    <tr v-if="index > 0">
                      <td class="text-left">{{t[0]}}</td>
                      <td class="text-right">{{$filters.currency(t[1])}}</td>
                      <td class="text-right">{{getPorcentaje(t[1])|ceil10}}%</td>
                    </tr>
                   </template>
                   
                  </tbody>
                  <tfoot>
                   <tr>
                      <th class="text-left">Total</th>
                      <th class="text-right"> {{$filters.currency(info.estadoCarteraTotal)}} </th>
                      <th></th>
                    </tr>
                  </tfoot>
                </template>
              </v-simple-table>                
                
            </v-col>
            <v-col cols="12" sm="6" class="form-col" v-if="info" style="border: 1px solid #ccc">
             <GChart type="PieChart" :data="info.detalle_cartera.estadoCartera" :options="info.detalle_cartera.optionsPieEstadoCartera" />
            </v-col>
   
            <v-col cols="12" sm="12" class="form-col" v-if="info.detalle_cartera.carteraEdades" style="border: 1px solid #ccc">
             <GChart type="PieChart" :data="info.detalle_cartera.carteraEdades" :options="info.detalle_cartera.optionsChartCarteraEdades" />
            </v-col>
            
            <v-col cols="12" sm="12" class="form-col" v-if="info.detalle_cartera.evolucion" style="border: 1px solid #ccc">
             <GChart type="LineChart" :data="info.detalle_cartera.evolucion" :options="info.detalle_cartera.optionsChartEvolucion" />
            </v-col>
           
            
            
            <v-col cols="12" sm="12" class="form-col" style="border: 1px solid #ccc;">
              <v-subheader>Detalle Mensual</v-subheader>
              <v-progress-circular v-if="loading"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-simple-table v-if="info">
                <template v-slot:default>
                  <thead>
                  <tr>
                      <th class="text-left">Periodo</th>
                      <th class="text-right">Cuotas Generadas (CG)</th>
                      <th class="text-right">Acumulado (CG)</th>
                      <th class="text-right">Recaudos (R)</th>
                      <th class="text-right">Acumulado(R)</th>
                      <th class="text-left">Flujo/mes</th>
                      <th class="text-right">Saldo/acumulados</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in resumenAcumulado">
                     <td class="text-right"> {{item.periodo}}</td>
                      <td class="text-right"> {{$filters.currency(item.ventas)}} </td>
                      <td class="text-right"> {{$filters.currency(item.vacumulado)}} </td>
                      <td class="text-right"> {{$filters.currency(item.ingresos)}}</td>
                      <td class="text-right"> {{$filters.currency(item.iacumulado)}}</td>
                      <td class="text-right"> {{$filters.currency(item.flujo)}}</td>
                      <td class="text-right"> {{$filters.currency(item.saldo)}}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
           </v-col>
          </v-row>
          
          <br><br>
          <b>Nota: </b><br>
          <p><b>1.</b>	Las obligaciones de Serprogreso como administrador de cartera de créditos relacionadas con la gestión de los créditos son de medio y no de resultado.  La gestión de la cartera de créditos está sujeta a riesgos propios de una operación de crédito, los cuales estarán a cargo del acreedor.</p>
          <p><b>2.</b>	A través de la App Serprogreso Móvil podrá revisar el detalle de su cartera de créditos y la gestión realizada.</p>


  
      </div>


    </div>
  </div>
</template>

<script>
export default {
  name: "RentabilidadDetalle",
  components: {},
  data() {
    return {
      info: {
        detalle_cartera: [{ id: 1 }],
        dataPieCartera: [],
        cartera_total: [{ id: 1 }],
      },
      loading: false,
      resumenAcumulado: [],
      chartDistribucionCarteraProducto: {
        chartOptions: {
          colors: ["green", "blue", "#e6cf25", "#f55e27", "red"],
          pieSliceText: "value",
          legend: { position: "labeled", maxLines: 3 },
        },
      },
      chartRentabilidades: {
        chartOptions: {
          title: "Acumulado Año por Producto",
          colors: ["green", "blue", "#e6cf25", "#f55e27", "red"],
          vAxis: { title: "%" },
          legend: { position: "none", maxLines: 3 },
        },
      },
      chartRentabilidadesProducto: {
        chartOptions: {
          title: "Rentabilidad Mensual",
          colors: ["green", "blue", "#e6cf25", "#f55e27", "red"],
          legend: { position: "none", maxLines: 3 },
          vAxis: { title: "%" },
        },
      },
      pieChartDistribucionPagosProducto: {
        chartOptions: {
          title: "Distribución % pagos X producto",
          colors: ["green", "blue", "#e6cf25", "#f55e27", "red"],
          legend: { position: "labeled", maxLines: 3 },
          chartArea: { left: 10, top: 10, width: "100%", height: "75%" },
        },
      },
      pieChartDistribucionInteresProducto: {
        chartOptions: {
          title: "Distribución % interés neto X producto",
          subtitle: "",
          colors: ["green", "blue", "#e6cf25", "#f55e27", "red"],
          legend: { position: "labeled", maxLines: 3 },
          chartArea: { left: 10, top: 10, width: "100%", height: "65%" },
        },
      },
      barChartCostoRentabilidad: {
        chartOptions: {
          title: "Costos vs Rentabilidad",
          colors: ["green", "blue", "#e6cf25", "#f55e27", "red"],
          legend: { position: "top", maxLines: 3 },
          vAxis: { title: "%" },
        },
      },
      renderKey: 1,
      tmpl: "",
      dialog: false,
    };
  },
  props: [],
  created() {
    let u = localStorage.getItem("user");
    this.user = JSON.parse(u);
    this.search();
  },
  methods: {
    close() {
      this.$router.push("/admin/report/rentabilidad/list");
    },
    search() {
      this.$http
        .post(
          "api/admin/resumen-rentabilidad/" + this.$route.params.id,
          {},
          this,
          true
        )
        .then((res) => {
          if (res.success) {
            this.info = res.results;
            this.getResultadosAnho();
          }

          //this.tmpl = res.data.html;
          this.renderKey++;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getResultadosAnho() {
      let data = JSON.parse(
        JSON.stringify(this.info.detalle_cartera.evolucion)
      );
      //let dataReversed = data.reverse();
      data.shift();
      const resume = [];
      let vacumulado = 0;
      let iacumulado = 0;
      for (const d of data) {
        vacumulado += parseFloat(d[1]);
        iacumulado += parseFloat(d[2]);
        resume.push({
          periodo: d[0],
          ventas: d[1],
          vacumulado: vacumulado,
          ingresos: d[2],
          iacumulado: iacumulado,
          flujo: d[1] - d[2],
          saldo: vacumulado - iacumulado,
        });
      }

      this.resumenAcumulado = resume.reverse();
    },
    getInfoPieClientes() {
      // this.resumen = response.data.resumen;
      if (!this.info || !this.info.detalle_cartera.clientes) {
        return [
          ["Estado", "Valor"],
          ["Sin Deuda", 1],
          ["Con Deuda No Vencida", 0],
          ["Con Deuda Vencida", 0],
        ];
      } else {
        return [
          ["Estado", "Valor"],
          ["Sin Deuda", 0],
          [
            "Con Deuda No Vencida",
            this.info.detalle_cartera.clientes.total_deuda_no_vencida,
          ],
          [
            "Con Deuda Vencida",
            this.info.detalle_cartera.clientes.total_deuda_vencida,
          ],
        ];
      }
    },
    getPorcentaje(v) {
      if (!v) {
        v = 0;
      }
      let total = this.getTotalDistribucion(
        this.info.detalle_cartera.estadoCartera
      );
      if (!total) {
        total = 1;
      }
      // console.log(v, total)
      return (v / total) * 100;
    },
    getTotalDistribucion(data) {
      //  console.log(data)
      let total = 0;
      for (const i in data) {
        if (i > 0) {
          if (!data[i][1]) {
            data[i][1] = 0;
          }
          total += data[i][1];
        }
      }
      return total;
    },

    calcTotalCreditos() {
      let total = 0;
      if (this.info.creditos) {
        for (const cr of this.info.creditos) {
          total += parseFloat(cr.valor_credito);
        }
      }
      return total;
    },
    calcTotalSaldo() {
      let total = 0;
      if (this.info.creditos) {
        for (const cr of this.info.creditos) {
          total += parseFloat(cr.saldo);
        }
      }
      return total;
    },
    calcTotalRendimiento() {
      let total = 0;
      for (const i in this.info.rentabilidad_generada_periodo_xproducto) {
        const cr = this.info.rentabilidad_generada_periodo_xproducto[i];
        total += parseFloat(cr.rendimiento);
      }
      return total;
    },
    sumarItems(items, field) {
      let total = 0;
      for (const item of items) {
        if (!item[field] || item[field] == "NaN") {
          item[field] = 0;
        }
        total += parseFloat(item[field]);
      }
      return total;
    },
    calcularTotalCapital(items, field) {
      let total = 0;
      let creditosSumados = {};
      for (const item of items) {
        //console.log(item)
        if (!creditosSumados[item.item]) {
          if (!item[field] || item[field] == "NaN") {
            item[field] = 0;
          }
          total += parseFloat(item[field]);
          creditosSumados[item.item] = true;
        }
      }
      return total;
    },
    getCostoTransferencia(val) {
      if (!val || val == "NaN") {
        return 0;
      }
      return parseFloat(val);
    },
    getDataChartRentabilidadGeneradaPorAnho(info) {
      const result = [["", ""]];
      for (const monthInfo of info) {
        result.push([monthInfo.periodo, monthInfo.rentabilidad]);
      }
      return result;
    },
    getDataChartRentabilidadGeneradaPorAnhoPorProducto(info) {
      const result = [["", ""]];
      const totales = {};
      for (const periodo in info) {
        for (const prod in info[periodo]) {
          if (!totales[prod]) {
            totales[prod] = 0;
          }
          if (
            !info[periodo][prod]["rentabilidad"] ||
            info[periodo][prod]["rentabilidad"] == "NaN"
          ) {
            info[periodo][prod]["rentabilidad"] = 0;
          }
          totales[prod] += parseFloat(info[periodo][prod]["rentabilidad"]);
        }
      }
      for (const i in totales) {
        result.push([i, totales[i]]);
      }
      return result;
    },
  },
};
</script>

